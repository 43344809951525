import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`What I do`}</h2>
    <ProjectCard title="CodeKarate.com" link="https://codekarate.com" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  I create educational web development materials about Gatsby and Drupal.
    </ProjectCard>
    <ProjectCard title="Gatsby" link="https://gatsbyjs.com/about" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  I am making the web a faster place at Gatsby where I am focused on improving Gatsby's integration with Drupal.
    </ProjectCard>
    <ProjectCard title="Tech Talks" link="https://noti.st/codekarate" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  I like to talk about web technology like Gatsby and Drupal at various conferences.
    </ProjectCard>
    <ProjectCard title="Music" link="" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  I like to play guitar and (may at some point) record some of my music .
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
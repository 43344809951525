import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote">{`I'm a developer focused on building a better web. I'm a proponent for K-12 STEM/STEAM education and developing the next generation of engineers and scientists.`}</p>
    </blockquote>
    <p>{`I am a Staff Engineer at Gatsby working on making Gatsby and Drupal play nice together. I will probably add more to this later...`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      